export const BILDER = [
  {
    id: 0,
    image: "IMG_0639.jpg",
    tittel: "Hus1",
    beskrivelse: "Hus",
  },
  {
    id: 1,
    image: "IMG_0641.jpg",
    tittel: "Hus2",
    beskrivelse: "Hus",
  },
  {
    id: 2,
    image: "IMG_0643.jpg",
    tittel: "Hus3",
    beskrivelse: "Hus",
  },
  {
    id: 3,
    image: "IMG_0645.jpg",
    tittel: "Hus4",
    beskrivelse: "Hus",
  },
];
