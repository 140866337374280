import { React } from "react";
import "../App.css";
import "./media.css";

const Tjenester = (props) => {
  return (
    <div className="App">
      <br />
      <h5 className="cardTitle">Tjenester</h5>

      <br />
      <br />
      <p>
        Byggmester Tronvik & Koa AS utfører arbeid innen nybygg, tilbygg og
        rehabilitering.
        <br />
        <br />
        Vi har tett samarbeid med andre fag, og kan tilby totalentreprise med
        arkitekt og byggesøknad.
        <br />
        <br />
        Bedriften tar oppdrag i Trondheim, Malvik, Stjørdal og omegn.
        <br />
        <br />
        <b>Vi utfører blant annet:</b>
        <br />
        <br />
        Nybygg
        <br /> Tilbygg
        <br />
        Rehabilitering
        <br /> Endring av planløsninger <br />
        Rehabilitering av rom innvendig <br />
        Gulvlegging
        <br /> Innredning av kjellere og loft
        <br />
        Etterisolering/vindtetting
        <br /> Bytte av utvendig panel og fasade
        <br /> Dør og vindusbytte
        <br /> Rehabilitering/bytte av tak
        <br /> Takoppløft
        <br /> Kjøkken <br />
        Bad/vaskerom <br />
        Terrasser/uteområder <br />
        Garasjer
      </p>
      <br />
      <br />
      <br />
    </div>
  );
};
export default Tjenester;
