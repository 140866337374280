import { React } from "react";
import { Navbar } from "reactstrap";

import { Icon } from "./widgets";

import "./media.css";

const NavBarBottomFixed = () => {
  return (
    <div className="App">
      <Navbar className="navbar fixed-bottom navbar-light navbar-expand-sm bg-black">
        <div className="container">
          <a
            style={{ textDecoration: "none" }}
            id="brand1"
            href="tel:992-41-145"
          >
            <Icon.Phone size={25} />
          </a>
          <a
            style={{ textDecoration: "none" }}
            id="brand1"
            href="mailto: post@tronvik-koa.no"
          >
            <Icon.Mail size={25} />
          </a>
          <a
            style={{ textDecoration: "none" }}
            id="brand1"
            href="https://m.facebook.com/Byggmester-Tronvik-Koa-104116811980023/"
          >
            <Icon.Facebook size={25} />
          </a>
          <a
            href="https://www.google.no/maps/search/?api=1&query=Øyavegen+25,+7550+Hommelvik"
            style={{ textDecoration: "none" }}
            id="brand1"
          >
            <Icon.Location size={25} />
          </a>
        </div>
      </Navbar>
    </div>
  );
};

export default NavBarBottomFixed;
