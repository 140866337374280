import { Component } from "react";
import React from "react";
import { Link } from "react-router-dom";
import "./media.css";

export class CardLink extends Component {
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{this.props.title}</h5>
          <Link to={this.props.to} alt={this.props.alt}>
            <div className="card-text">{this.props.children}</div>
          </Link>
        </div>
      </div>
    );
  }
}

export class Card extends Component {
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{this.props.title}</h5>

          <div className="card-text">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export class Card1 extends Component {
  render() {
    return (
      <div className="card1">
        <div className="card-body">
          <h5 className="card-title">{this.props.title}</h5>

          <div className="card-text">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export class Row extends Component {
  render() {
    return <div className="row">{this.props.children}</div>;
  }
}

export class Column extends Component {
  render() {
    return (
      <div className={"col" + (this.props.width ? "-" + this.props.width : "")}>
        <div className={"float-" + (this.props.right ? "end" : "start")}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

/* FORM */

class FormLabel extends Component {
  render() {
    return <label className="col-form-label">{this.props.children}</label>;
  }
}

class FormInput extends Component {
  render() {
    return (
      <input
        className="form-control"
        type={this.props.type}
        value={this.props.value}
        onChange={this.props.onChange}
        required={this.props.required}
        pattern={this.props.pattern}
        placeholder={this.props.placeholder}
      />
    );
  }
}
class TextArea extends Component {
  render() {
    return (
      <div className="form-floating">
        <textarea
          placeholder={this.props.placeholder}
          // id="floatingTextarea"
          id={this.props.id}
          value={this.props.value}
          defaultValue={this.props.defaultValue}
          onChange={this.props.onChange}
          className="form-control"
          style={{
            height: "250px",
          }}
          required={this.props.required}
        ></textarea>
        <label htmlFor="floatingTextarea">{this.props.children}</label>
      </div>
    );
  }
}

export class Form {
  static Label = FormLabel;
  static Input = FormInput;
  static TextArea = TextArea;
}

/* ICONS */

export class Phone extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.size}
        height={this.props.size}
        fill="currentColor"
        className="bi bi-telephone"
        viewBox="0 0 16 16"
      >
        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
      </svg>
    );
  }
}

export class Mail extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.size}
        height={this.props.size}
        fill="currentColor"
        className="bi bi-envelope"
        viewBox="0 0 16 16"
      >
        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
      </svg>
    );
  }
}

export class Location extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.size}
        height={this.props.size}
        fill="currentColor"
        className="bi bi-geo-alt"
        viewBox="0 0 16 16"
      >
        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
      </svg>
    );
  }
}

export class Facebook extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.size}
        height={this.props.size}
        fill="currentColor"
        className="bi bi-facebook"
        viewBox="0 0 16 16"
      >
        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
      </svg>
    );
  }
}

export class Icon extends Component {
  static Phone = Phone;
  static Mail = Mail;
  static Location = Location;
  static Facebook = Facebook;
}

/* BUTTON */

class ButtonSuccess extends Component {
  render() {
    return (
      <button
        type="button"
        className="btn btn-outline-success"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}

class ButtonDanger extends Component {
  render() {
    return (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}

class ButtonLight extends Component {
  render() {
    return (
      <button
        type="button"
        className="btn btn-outline-secondary"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}

class ButtonSmall extends Component {
  render() {
    return (
      <button
        type="button"
        className="btn btn-outline-secondary btn-m"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}

class ButtonOrange extends Component {
  render() {
    return (
      <button
        type="button"
        className="btn btn-outline-danger btn-l orange"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}

class ButtonNav extends Component {
  render() {
    return (
      <button
        type="button"
        className="btn nav-btn btn-m"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}

export class Button {
  static Success = ButtonSuccess;
  static Danger = ButtonDanger;
  static Light = ButtonLight;
  static Small = ButtonSmall;
  static Orange = ButtonOrange;
  static Nav = ButtonNav;
}
