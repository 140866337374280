export const ANSATTE = [
  {
    id: 0,
    name: "Torkel Tronvik",
    image: "Torkel.jpg",
    tittel: "Styrets leder",
    telefon: "992 41 145",
    epost: "torkel@tronvik-koa.no",
    født: "1989",
  },
  {
    id: 1,
    name: "Martin Koa",
    image: "Martin.jpg",
    tittel: "Daglig leder",
    telefon: "951 08 156",
    epost: "martin@tronvik-koa.no",
    født: "1990",
  },
];
