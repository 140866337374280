import { React } from "react";
import "./App.css";
import NavBarTest from "./components/navBarTest";
import NavBar from "./components/navBar";
import NavBarBottomFixed from "./components/navBarBottomFixed";
import FrontPage from "./components/frontPage";
import OmOss from "./components/omOss";
import Tjenester from "./components/tjenester";
import Galleri from "./components/galleri";
import Ansatte from "./components/ansatte";
import KontaktOss from "./components/kontaktOss";
import { Switch, Route } from "react-router-dom";

const App = () => {
  return (
    <>
      <NavBar />
      <Switch>
        <Route exact path="/">
          <FrontPage />
        </Route>
        <Route exact path="/omOss">
          <OmOss />
        </Route>
        <Route exact path="/tjenester">
          <Tjenester />
        </Route>
        <Route exact path="/galleri">
          <Galleri />
        </Route>
        <Route exact path="/ansatte">
          <Ansatte />
        </Route>
        <Route exact path="/kontaktOss">
          <KontaktOss />
        </Route>
      </Switch>
      <NavBarBottomFixed />
    </>
  );
};

export default App;
