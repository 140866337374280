import { React, useState } from "react";
import { BILDER } from "../shared/bilder";
import { Container, Row, Col } from "reactstrap";
import "../App.css";
import "./media.css";

const Galleri = (props) => {
  const initialState = () => BILDER;
  const [bilder] = useState(initialState);
  const bildeListe = bilder.map((bilde) => {
    return (
      <div key={bilde.id}>
        <h5>{bilde.tittel}</h5>
        <img
          object
          src={process.env.PUBLIC_URL + `images/${bilde.image}`}
          alt={`${bilde.tittel}`}
          className="galleriBilde"
          width={"250px"}
          height={"auto"}
        />
        <br />
        <br />
        <p> {bilde.beskrivelse}</p>
      </div>
    );
  });
  return (
    <div className="App">
      <br />
      <h5 className="cardTitle">Galleri</h5>
      <br />
      <br />
      <Container>
        <Row>
          {bilder.map((bilde) => {
            return (
              <Col>
                <div className="card2">{bildeListe[bilde.id]}</div>
              </Col>
            );
          })}
        </Row>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
export default Galleri;
