import { React } from "react";
import "../App.css";

import "./media.css";

const OmOss = () => {
  return (
    <div className="App">
      <br />
      <h5 className="card-title">Byggmester Tronvik og Koa AS</h5>
      <br />
      <br />
      <p>
        Byggmester Tronvik og Koa AS er en byggmesterbedrift som holder til i
        Malvik kommune. Bedriften drives av Torkel Husdal Tronvik og Martin Koa
        Edvardsen.
        <br />
        <br />
        Martin Koa Edvardsen etablerte i 2019 bedriften Byggmester Martin Koa
        AS, som han har drevet fram til høsten 2021. Høsten 2021 kom Torkel
        Tronvik på eiersiden og vi endret navnet til Byggmester Tronvik og Koa
        AS.
        <br />
        <br />
        Til sammen har vi godt over 20 års erfaring innen tømrerfaget, to
        mesterbrev og kompetansen som trengs for både små og store oppdrag.
        <br />
        <br />
        Med 2 byggmestere på laget og seriøse samarbeidspartnere har vi fokus på
        å imøtekomme oppdragsgivers behov, og kan skreddersy et produkt i tråd
        med kunden sine ønsker.
        <br />
        <br />
        Vårt kontorlokale ligger i Øyavegen 25 i Hommelvik, i 2. etasje ovenfor
        Byggtorget Hommelvik.
      </p>
      <br />
      <br />
    </div>
  );
};
export default OmOss;
