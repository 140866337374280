import { React, useState } from "react";
import {
  Navbar,
  ButtonGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Button } from "./widgets";
import { useHistory } from "react-router-dom";
import NavButtons from "./navButtons";

import "./media.css";

const NavBar = () => {
  let history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div>
      <nav className="navbar navbar-light navbar-expand-sm bg-black">
        <span class="text-nowrap">
          <div
            className="btn btn-link"
            role="button"
            style={{ textDecoration: "none" }}
            id="brand1"
            onClick={() => {
              history.push(`/`);
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                "images/Skjermbilde 2021-08-13 kl. 22.49.53.png"
              }
              alt=""
              height="55"
              width="auto"
            ></img>
            <img
              src={process.env.PUBLIC_URL + "images/mester-logo-hvit.png"}
              height="55"
              width="auto"
              alt=""
            ></img>
          </div>
        </span>
        <div className="collapsed">
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret className="btn-custom">
              <b>Meny</b>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => {
                  history.push(`/`);
                }}
              >
                Forside
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  history.push(`/omOss`);
                }}
              >
                Om oss
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  history.push(`/tjenester`);
                }}
              >
                Tjenester
              </DropdownItem>

              {/*
              <DropdownItem
                onClick={() => {
                  history.push(`/galleri`);
                }}
              >
                Galleri
              </DropdownItem>
              */}
              <DropdownItem
                onClick={() => {
                  history.push(`/ansatte`);
                }}
              >
                Ansatte
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  history.push(`/kontaktOss`);
                }}
              >
                Kontakt
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="full">
          <ButtonGroup>
            {
              <Button.Nav
                onClick={() => {
                  history.push(`/omOss`);
                }}
              >
                <span class="text-nowrap">
                  <b>Om Oss</b>
                </span>
              </Button.Nav>
            }
            {
              <Button.Nav
                onClick={() => {
                  history.push(`/tjenester`);
                }}
              >
                <b>Tjenester</b>
              </Button.Nav>
            }
            {/*
              <Button.Nav
                onClick={() => {
                  history.push(`/galleri`);
                }}
              >
                <b>Galleri</b>
              </Button.Nav>
              */}
            {
              <Button.Nav
                onClick={() => {
                  history.push(`/ansatte`);
                }}
              >
                <b>Ansatte</b>
              </Button.Nav>
            }
            {
              <Button.Nav
                onClick={() => {
                  history.push(`/kontaktOss`);
                }}
              >
                <b>Kontakt</b>
              </Button.Nav>
            }
          </ButtonGroup>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
