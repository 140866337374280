import { React } from "react";
import "../App.css";
import "./media.css";
import { Button } from "./widgets";

const FrontPage = () => {
  return (
    <div className="bg">
      <br></br>
      <br></br>
      <p className="intro">Vi tilbyr tjenester innen</p>
      <p className="intro2">Nybygg, tilbygg og rehabilitering</p>
      <a href="tel:992-41-145">
        <Button.Orange>
          <b>Ring oss</b>
        </Button.Orange>
      </a>
    </div>
  );
};

export default FrontPage;
