import { React, useState } from "react";
import "./media.css";
import "../App.css";
import { Container, Row, Col } from "reactstrap";
import { ANSATTE } from "../shared/ansatte";

const Ansatte = () => {
  const initialState = () => ANSATTE;
  const [ansatte] = useState(initialState);

  const anstatteListe = ansatte.map((ansatt) => {
    return (
      <div key={ansatt.id}>
        <h5>Byggmester {ansatt.name}</h5>
        <br />

        <img
          object
          src={process.env.PUBLIC_URL + `images/${ansatt.image}`}
          alt={`${ansatt.name}`}
          className="ansattBilde"
          width={"250px"}
          height={"auto"}
        />
        <br />
        <br />
        {ansatt.tittel}
        <br />
        <br />
        <a
          style={{ textDecoration: "none" }}
          id="brand5"
          href={`mailto: ${ansatt.epost}`}
        >
          {ansatt.epost}
        </a>
        <br />
        <br />
        <a
          style={{ textDecoration: "none" }}
          id="brand5"
          href={`tel:${ansatt.telefon}`}
        >
          {ansatt.telefon}
        </a>
      </div>
    );
  });

  return (
    <div className="App">
      <br />
      <br />
      <Container>
        <Row>
          <Col>
            <div className="card2">{anstatteListe[0]}</div>
          </Col>
          <Col>
            <div className="card2">{anstatteListe[1]}</div>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
export default Ansatte;
