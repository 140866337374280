import { React, useState } from "react";
import "../App.css";
import "./media.css";
import { Row, Column, Form, Button } from "./widgets";
import * as emailjs from "emailjs-com";
import { init } from "emailjs-com";
import { useHistory } from "react-router-dom";
import "./media.css";

init("user_Z9hKs8iISFqqOjaR1Cri1");

const KontaktSkjema = () => {
  let history = useHistory();
  const initialState = () => "";
  const [navn, setNavn] = useState(initialState);
  const [epost, setEpost] = useState(initialState);
  const [melding, setMelding] = useState(initialState);
  return (
    <div className="App1">
      <Column>
        <Row>
          <Column>
            <Form.Input
              type={"text"}
              placeholder={"Navn"}
              value={navn}
              onChange={(e) => {
                let newNavn = e.currentTarget.value;
                e.preventDefault();
                setNavn(newNavn);
              }}
              required
            />
          </Column>
          <Column>
            <Form.Input
              type={"text"}
              placeholder={"Epost"}
              value={epost}
              onChange={(e) => {
                let newEpost = e.currentTarget.value;
                e.preventDefault();
                setEpost(newEpost);
              }}
              required
            />
          </Column>
        </Row>
        <br />
        <Row>
          <Form.TextArea
            value={melding}
            onChange={(e) => {
              let newMelding = e.currentTarget.value;
              e.preventDefault();
              setMelding(newMelding);
            }}
            required
          >
            <label>Hva gjelder det?</label>
          </Form.TextArea>
        </Row>
      </Column>
      <Button.Orange
        onClick={() => {
          const navn1 = navn;
          const epost1 = epost;
          const melding1 = melding;

          let skjemaParams = {
            email: epost1,
            to_name: "jorgen.t.nilsen@icloud.com",
            melding: melding1,
            navn: navn1,
          };
          emailjs
            .send(
              "service_oyu1g9k",
              "template_kxytczo",
              skjemaParams,
              "user_Z9hKs8iISFqqOjaR1Cri1"
            )
            .then(
              function () {
                console.log("SUCCESS!");
                alert("Melding sendt, vi svarer så fort som mulig");
                history.push("/");
              },
              function (error) {
                console.log("FAILED...", error);
                alert(
                  "Noe gikk galt, send oss en Epost på post@tronvik-koa.no, eller ring oss på !!! BYTT DETTE !!!"
                );
              }
            );
        }}
      >
        <b>Send</b>
      </Button.Orange>
      <br />
    </div>
  );
};

export default KontaktSkjema;
