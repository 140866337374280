import { React } from "react";
import "../App.css";

import "./media.css";

import KontaktSkjema from "./kontaktSkjema";

const KontaktOss = () => {
  return (
    <div className="App">
      <>
        <br />
        <h5 className="card-title">Kontakt Oss</h5>
        <br />
        <br />
        <p>
          Martin Koa:{" "}
          <a
            style={{ textDecoration: "none" }}
            id="brand5"
            href={`tel:951-08-156`}
          >
            <b>951 08 156</b>
          </a>
        </p>
        <p>
          Torkel Tronvik:{" "}
          <a
            style={{ textDecoration: "none" }}
            id="brand5"
            href={`tel:992-41-145`}
          >
            <b>992 41 145</b>
          </a>
        </p>
        E-post:
        <a
          style={{ textDecoration: "none" }}
          id="brand5"
          href={`mailto: post@tronvik-koa.no`}
        >
          <b> post@tronvik-koa.no </b>
        </a>
       {/* 
        eller bruk kontakskjemaet under
        <br />
        <br />
        <KontaktSkjema />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        */}
      </>
      
    </div>
  );
};
export default KontaktOss;
